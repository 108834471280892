<template>
  <MainLayout>
    <div class="row cl-090D2E">
      <div class="mb-5 mt-4 mt-md-auto mb-md-3 ypd-typo-main-title">
        Your personal details
      </div>
      <p>
        We need more information about you to prepare the necessary documents
        and fulfil compliance obligations.
      </p>
      <p>
        Please check the completeness of the data before saving it. Especially
        the postal address (where the contract will be sent) and the telephone
        number (you must enter it in international format: +41 44 5152593, for
        example).
      </p>
      <p>
        We will pass these details on to the bank to open an investment account
        for you (brokerage account). All data transfers between you,
        Simplewealth and the bank are protected with 128-bit encryption and a
        PGP key.
      </p>
    </div>

    <div
      class="row mt-5"
      id="about-tab"
    >
      <a
        class="ypd-bold-head d-flex align-items-center"
        href="#about-tab"
      >
        <div
          class="swi swi_mini d-inline-block me-2"
          :class="[activeTab == 'about' ? 'arrow-down' : 'arrow-forward']"
        />
        About you
      </a>

      <div class="col-12 mt-4 d-flex flex-wrap">
        <div class="col-md-6 col-12 pe-md-2">
          <div class="sw-label">
            <div class="sw-label-text mb-3">
              Gender
            </div>
            <div class="d-flex swl-content">
              <div class="sw-radio me-5">
                <input
                  type="radio"
                  id="marital-status-radio-single"
                  checked
                  name="marital-status-radio"
                >
                <label for="marital-status-radio-single">Male</label>
              </div>
              <div class="sw-radio">
                <input
                  type="radio"
                  id="marital-status-radio-married"
                  name="marital-status-radio"
                >
                <label for="marital-status-radio-married">Female</label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-12 ps-md-2 mt-4 mt-md-auto">
          <div class="sw-label">
            <div class="sw-label-text">
              Date of birth
            </div>
            <div class="d-flex swl-content">
              <div class="sw-textfield w-100">
                <input
                  type="text"
                  placeholder="01 Jan 1970"
                  :value="new Date(form?.aboutYou?.dob).getDate() +' '+ new Date(form?.aboutYou?.dob).getMonth() +' '+ new Date(form?.aboutYou?.dob).getFullYear()"
                >
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-12 mt-4 pe-md-2">
          <div class="sw-label">
            <div class="sw-label-text">
              First name
            </div>
            <div class="d-flex swl-content">
              <div class="sw-textfield w-100">
                <input
                  type="text"
                  placeholder="Your first name"
                >
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-12 mt-4 ps-md-2">
          <div class="sw-label">
            <div class="sw-label-text">
              Last name
            </div>
            <div class="d-flex swl-content">
              <div class="sw-textfield w-100">
                <input
                  type="text"
                  placeholder="Your last name"
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-12 mb-4">
        <h3 class="ypd-typo-form-title">
          Your residential address
        </h3>
        <div class="d-flex mt-4 flex-wrap">
          <div class="sw-label col-md-6 col-12 mt-4 pe-md-2">
            <div class="sw-label-text">
              Country
            </div>
            <div class="d-flex swl-content">
              <v-select
                :options="options"
                close-on-select
                placeholder="Choose option"
                class="sw-select"
              />
            </div>
          </div>

          <div class="sw-label col-md-6 col-12 mt-4 ps-md-2">
            <div class="sw-label-text">
              State/province
            </div>
            <div class="d-flex swl-content">
              <v-select
                :options="options"
                close-on-select
                placeholder="Choose option"
                class="sw-select"
              />
            </div>
          </div>

          <div class="col-md-6 col-12 mt-4 pe-md-2 sw-label">
            <div class="sw-label-text">
              City
            </div>
            <div class="d-flex swl-content">
              <div class="sw-textfield w-100">
                <input
                  type="text"
                  placeholder="Enter address"
                >
              </div>
            </div>
          </div>

          <div class="col-md-6 col-12 mt-4 ps-md-2 sw-label">
            <div class="sw-label-text">
              Postal code
            </div>
            <div class="d-flex swl-content">
              <div class="sw-textfield w-100">
                <input
                  type="text"
                  placeholder="Enter postal code"
                >
              </div>
            </div>
          </div>

          <div class="col-12 mt-4 sw-label">
            <div class="sw-label-text">
              Your full address
            </div>
            <div class="d-flex swl-content">
              <div class="sw-textfield w-100">
                <input
                  type="text"
                  placeholder="Enter street name, house number, apt"
                >
              </div>
            </div>
          </div>

          <div class="sw-label col-md-6 col-12 mt-4 pe-md-2">
            <div class="sw-label-text">
              Phone country
            </div>
            <div class="d-flex swl-content">
              <v-select
                :options="options"
                close-on-select
                placeholder="Choose option"
                class="sw-select"
              />
            </div>
          </div>

          <div class="col-md-6 col-12 mt-4 ps-md-2 sw-label">
            <div class="sw-label-text">
              Mobile phone number
            </div>
            <div class="d-flex swl-content">
              <div class="sw-textfield w-100">
                <input
                  type="text"
                  placeholder="+49"
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 mt-4">
        <div
          class="d-flex align-items-center col-12 pb-45"
          :class="{ 'bordered-wrapper': !abFirstBlock }"
        >
          <div class="col-10 ypd-typo-text">
            I have different mailing address
          </div>
          <div class="col-2 d-flex justify-content-end">
            <div class="sw-switch">
              <input
                type="checkbox"
                id="switch-1"
                name="switch-1"
                v-model="abFirstBlock"
              >
              <label for="switch-1" />
            </div>
          </div>
        </div>

        <div
          class="col-12 d-flex flex-wrap mt-4"
          v-if="abFirstBlock"
        >
          <h3 class="col-12 mb-5 ypd-typo-form-title">
            Your mailing address
          </h3>

          <div class="sw-label col-md-6 col-12 mb-4 pe-md-2">
            <div class="sw-label-text">
              Country
            </div>
            <div class="d-flex swl-content">
              <v-select
                :options="options"
                close-on-select
                placeholder="Choose option"
                class="sw-select"
              />
            </div>
          </div>

          <div class="sw-label col-md-6 col-12 mb-4 ps-md-2">
            <div class="sw-label-text">
              State/province
            </div>
            <div class="d-flex swl-content">
              <v-select
                :options="options"
                close-on-select
                placeholder="Choose option"
                class="sw-select"
              />
            </div>
          </div>

          <div class="col-md-6 col-12 mt-md-3 pe-md-2 sw-label">
            <div class="sw-label-text">
              City
            </div>
            <div class="d-flex swl-content">
              <div class="sw-textfield w-100">
                <input
                  type="text"
                  placeholder="Enter address"
                >
              </div>
            </div>
          </div>

          <div class="col-md-6 col-12 mt-3 ps-md-2 sw-label">
            <div class="sw-label-text">
              Postal code
            </div>
            <div class="d-flex swl-content">
              <div class="sw-textfield w-100">
                <input
                  type="text"
                  placeholder="Enter postal code"
                >
              </div>
            </div>
          </div>

          <div class="col-12 mt-3 sw-label bordered-wrapper pb-4">
            <div class="sw-label-text">
              Your full address
            </div>
            <div class="d-flex swl-content">
              <div class="sw-textfield w-100">
                <input
                  type="text"
                  placeholder="Enter street name, house number, apt"
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="col-12 d-flex bordered-wrapper pb-45 flex-wrap">
          <div class="col-12 col-md-6 mt-4 pe-md-2 sw-label">
            <div class="sw-label-text">
              Passport number
            </div>
            <div class="d-flex swl-content">
              <div class="sw-textfield w-100">
                <input
                  type="text"
                  placeholder="Enter passport number"
                >
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 mt-4 ps-md-2 sw-label">
            <div class="sw-label-text">
              Tax identification number
            </div>
            <div class="d-flex swl-content">
              <div class="sw-textfield w-100">
                <input
                  type="text"
                  placeholder="Enter tax number"
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 mt-45">
        <div
          class="d-flex align-items-center col-12 pb-45"
          :class="{ 'bordered-wrapper': !abSecondBlock }"
        >
          <div class="col-10 ypd-typo-text">
            I don’t have the Tax number of Greece
          </div>
          <div class="col-2 d-flex justify-content-end">
            <div class="sw-switch">
              <input
                type="checkbox"
                id="switch-2"
                name="switch-2"
                v-model="abSecondBlock"
              >
              <label for="switch-2" />
            </div>
          </div>
        </div>

        <div
          class="col-12 d-flex flex-wrap mt-4"
          v-if="abSecondBlock"
        >
          <div class="sw-label col-12 col-md-6 mb-3 pe-md-2 mt-3">
            <div class="sw-label-text">
              Choose the country of your Tax residency
            </div>
            <div class="d-flex swl-content">
              <v-select
                :options="options"
                close-on-select
                placeholder="Choose option"
                class="sw-select"
              />
            </div>
          </div>
          <div class="col-12 col-md-6 mt-3 ps-md-2">
            <div class="sw-label">
              <div class="sw-label-text">
                Tax identification number
              </div>
              <div class="d-flex swl-content">
                <div class="sw-textfield w-100">
                  <input
                    type="text"
                    placeholder="Enter tax number"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 mt-45">
        <div
          class="d-flex align-items-center col-12 pb-45"
          :class="{ 'bordered-wrapper': !abThirdBlock }"
        >
          <div class="col-10 ypd-typo-text">
            I have different Tax residency than Greece
          </div>
          <div class="col-2 d-flex justify-content-end">
            <div class="sw-switch">
              <input
                type="checkbox"
                id="switch-3"
                name="switch-3"
                v-model="abThirdBlock"
              >
              <label for="switch-3" />
            </div>
          </div>
        </div>

        <div
          class="col-12 d-flex flex-wrap mt-4"
          v-if="abThirdBlock"
        >
          <div class="col-12 ypd-typo-extra-small">
            US Internal Revenue Service rules require the collection of a
            taxpayer identification number (or equivalent) (TIN) for all of your
            tax residencies. Failure to provide a TIN may require us to treat
            your account as undocumented for US tax purposes and impose
            exceptional withholding on income and proceeds paid to the account.
            Update your entry and provide your TIN above. If you do not have a
            TIN, provide an appropriate reason (one selection required):
          </div>

          <div class="bordered-wrapper py-4 col-12 mt-3">
            <div class="sw-radio col-12 mb-2 mb-md-0">
              <input
                type="radio"
                id="1-radio"
                name="example-radio"
              >
              <label for="1-radio">
                I have a US taxpayer identification number and I will add it to
                the W8 tax form.
              </label>
            </div>
          </div>

          <div class="bordered-wrapper py-4 col-12">
            <div class="sw-radio col-12 mb-2 mb-md-0">
              <input
                type="radio"
                id="2-radio"
                name="example-radio"
              >
              <label for="2-radio">
                Greece does not issue TINs to its residents.
              </label>
            </div>
          </div>

          <div class="bordered-wrapper py-4 col-12">
            <div class="sw-radio col-12 mb-2 mb-md-0">
              <input
                type="radio"
                id="3-radio"
                name="example-radio"
              >
              <label for="3-radio">
                I am not legally required to obtain a TIN from Greece
              </label>
            </div>
          </div>
          <div class="bordered-wrapper py-4 col-12">
            <div class="sw-radio col-12 mb-2 mb-md-0">
              <input
                type="radio"
                id="4-radio"
                name="example-radio"
              >
              <label for="4-radio">
                TINs are issued, however, account holder is exempt from this
                requirement under the laws of Grece
              </label>
            </div>
          </div>
          <div class="bordered-wrapper py-4 col-12">
            <div class="sw-radio col-12 mb-2 mb-md-0">
              <input
                type="radio"
                id="5-radio"
                name="example-radio"
              >
              <label for="5-radio"> Other </label>
            </div>
          </div>

          <div class="col-12 mt-3 sw-label">
            <div class="d-flex swl-content">
              <div class="sw-textfield w-100">
                <input
                  type="text"
                  placeholder="Provide the reason here"
                >
              </div>
            </div>
          </div>

          <div class="col-12 my-4">
            <div class="d-flex align-items-center">
              <div class="swi plus-square swi_mini d-inline-block me-2" />
              Add one more affiliated person
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 mt-45">
        <div
          class="d-flex align-items-center col-12 pb-45"
          :class="{ 'bordered-wrapper': !abFoursBlock }"
        >
          <div class="col-10 ypd-typo-text">
            I have different citizenship than Greece
          </div>
          <div class="col-2 d-flex justify-content-end">
            <div class="sw-switch">
              <input
                type="checkbox"
                id="switch-4"
                name="switch-4"
                v-model="abFoursBlock"
              >
              <label for="switch-4" />
            </div>
          </div>
        </div>

        <div
          class="col-12 d-flex flex-wrap mt-4"
          v-if="abFoursBlock"
        >
          <div class="sw-label col-md-6 col-12 mb-3">
            <div class="sw-label-text">
              Choose your citizenship
            </div>
            <div class="d-flex swl-content">
              <v-select
                :options="options"
                close-on-select
                placeholder="Choose option"
                class="sw-select"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 mt-45">
        <div
          class="d-flex align-items-center col-12 pb-45"
          :class="{ 'bordered-wrapper': !abFiveBlock }"
        >
          <div class="col-10 ypd-typo-text">
            I have was born outside of Greece
          </div>
          <div class="col-2 d-flex justify-content-end">
            <div class="sw-switch">
              <input
                type="checkbox"
                id="switch-5"
                name="switch-5"
                v-model="abFiveBlock"
              >
              <label for="switch-5" />
            </div>
          </div>
        </div>

        <div
          class="col-12 d-flex flex-wrap mt-4"
          v-if="abFiveBlock"
        >
          <div class="sw-label col-md-6 col-12 mb-3">
            <div class="sw-label-text">
              Choose the country where you was born
            </div>
            <div class="d-flex swl-content">
              <v-select
                :options="options"
                close-on-select
                placeholder="Choose option"
                class="sw-select"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="row mt-5"
      id="life-tab"
    >
      <a
        class="ypd-bold-head d-flex align-items-center"
        href="#life-tab"
      >
        <div
          class="swi swi_mini d-inline-block me-2"
          :class="[activeTab == 'life' ? 'arrow-down' : 'arrow-forward']"
        />
        Your life situation and regulatory matters
      </a>

      <div class="col-12">
        <div class="col-12 mt-4 d-flex flex-wrap">
          <div class="col-md-6 col-12">
            <div class="sw-label">
              <div class="sw-label-text mb-3">
                Marital status
              </div>
              <div class="d-flex swl-content">
                <div class="sw-radio me-5">
                  <input
                    type="radio"
                    id="marital-status-radio-single"
                    checked
                    name="marital-status-radio"
                  >
                  <label for="marital-status-radio-single">Single</label>
                </div>
                <div class="sw-radio">
                  <input
                    type="radio"
                    id="marital-status-radio-married"
                    name="marital-status-radio"
                  >
                  <label for="marital-status-radio-married">Married</label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-12 mt-md-auto mt-4">
            <div class="sw-label">
              <div class="sw-label-text">
                Number of Depdendents
              </div>
              <div class="d-flex swl-content">
                <div class="sw-textfield w-100">
                  <input
                    type="text"
                    placeholder="0"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-12 mt-45">
          <div class="sw-label">
            <div class="sw-label-text">
              Employment type
            </div>
            <div class="d-flex swl-content">
              <v-select
                :options="options"
                close-on-select
                placeholder="Choose option"
                class="sw-select"
              />
            </div>
          </div>
        </div>

        <div class="col-12 mb-4 mt-6">
          <h3 class="cl-090D2E">
            Employer information
          </h3>
          <div class="d-flex mt-5 flex-wrap">
            <div class="sw-label col-12 mb-45">
              <div class="sw-label-text">
                Employer
              </div>
              <div class="d-flex swl-content">
                <div class="sw-textfield w-100">
                  <input
                    type="text"
                    placeholder="Enter employer"
                  >
                </div>
              </div>
            </div>

            <div class="sw-label col-md-6 col-12 pe-md-2 mb-45">
              <div class="sw-label-text">
                Your employer nature of business
              </div>
              <div class="d-flex swl-content">
                <v-select
                  :options="options"
                  close-on-select
                  placeholder="Choose option"
                  class="sw-select"
                />
              </div>
            </div>

            <div class="sw-label col-md-6 col-12 ps-md-2 mb-45">
              <div class="sw-label-text">
                Your occuption here
              </div>
              <div class="d-flex swl-content">
                <v-select
                  :options="options"
                  close-on-select
                  placeholder="Choose option"
                  class="sw-select"
                />
              </div>
            </div>

            <div class="sw-label col-md-6 col-12 pe-md-2 mb-45">
              <div class="sw-label-text">
                Employer country
              </div>
              <div class="d-flex swl-content">
                <v-select
                  :options="options"
                  close-on-select
                  placeholder="Choose option"
                  class="sw-select"
                />
              </div>
            </div>

            <div class="sw-label col-md-6 col-12 ps-md-2 mb-45">
              <div class="sw-label-text">
                State/Province
              </div>
              <div class="d-flex swl-content">
                <v-select
                  :options="options"
                  close-on-select
                  placeholder="Choose option"
                  class="sw-select"
                />
              </div>
            </div>

            <div class="sw-label col-md-6 col-12 pe-md-2 mb-45">
              <div class="sw-label-text">
                Employer city
              </div>
              <div class="d-flex swl-content">
                <div class="sw-textfield w-100">
                  <input
                    type="text"
                    placeholder="Enter employer city"
                  >
                </div>
              </div>
            </div>

            <div class="sw-label col-md-6 col-12 ps-md-2 mb-45">
              <div class="sw-label-text">
                Postal code
              </div>
              <div class="d-flex swl-content">
                <div class="sw-textfield w-100">
                  <input
                    type="text"
                    placeholder="Enter postal code"
                  >
                </div>
              </div>
            </div>

            <div class="sw-label col-12 mb-45">
              <div class="sw-label-text">
                Employer full address
              </div>
              <div class="d-flex swl-content">
                <div class="sw-textfield w-100">
                  <input
                    type="text"
                    placeholder="Enter employer full address"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12">
          <h3 class="ypd-typo-title">
            Additional income
          </h3>
          <div class="mt-5">
            <div class="d-flex mt-4 align-items-center col-12">
              <div class="col-10 ypd-typo-text">
                Will you use any additional sources of income to invest with
                Simplewealth?
              </div>
              <div class="col-2 d-flex justify-content-end">
                <div class="sw-switch">
                  <input
                    type="checkbox"
                    id="switch-1"
                    name="switch-1"
                    v-model="firstBlock"
                  >
                  <label for="switch-1" />
                </div>
              </div>
            </div>

            <div
              class="col-12"
              v-if="firstBlock"
            >
              <h5 class="mt-4 ypd-typo-small">
                How is your income divided? Write it down in a table (as a
                percentage, total not exceeding 100%)
              </h5>
              <div class="d-flex flex-column">
                <div
                  class="
                    col-12
                    d-flex
                    align-items-center
                    justify-content-between
                    mt-45
                  "
                >
                  <div class="col-6">
                    Income from Employment
                  </div>
                  <div class="col-auto">
                    <div class="sw-number sw-number_right sw-number_percent">
                      <input
                        type="number"
                        placeholder="0"
                      >
                      <div class="swn-percent">
                        %
                      </div>
                      <div class="sw-number_buttons">
                        <div
                          class="sw-number_up"
                          onclick="this.parentNode.parentNode.querySelector('input').stepUp()"
                        />
                        <div
                          class="sw-number_down"
                          onclick="this.parentNode.parentNode.querySelector('input').stepDown()"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="
                    col-12
                    d-flex
                    align-items-center
                    justify-content-between
                    mt-4
                  "
                >
                  <div class="col-6">
                    Allowance / Spousal Income
                  </div>
                  <div class="col-auto">
                    <div class="sw-number sw-number_right sw-number_percent">
                      <input
                        type="number"
                        placeholder="0"
                      >
                      <div class="swn-percent">
                        %
                      </div>
                      <div class="sw-number_buttons">
                        <div
                          class="sw-number_up"
                          onclick="this.parentNode.parentNode.querySelector('input').stepUp()"
                        />
                        <div
                          class="sw-number_down"
                          onclick="this.parentNode.parentNode.querySelector('input').stepDown()"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="
                    col-12
                    d-flex
                    align-items-center
                    justify-content-between
                    mt-4
                  "
                >
                  <div class="col-6">
                    Disability / Severance / Unemployment
                  </div>
                  <div class="col-auto">
                    <div class="sw-number sw-number_right sw-number_percent">
                      <input
                        type="number"
                        placeholder="0"
                      >
                      <div class="swn-percent">
                        %
                      </div>
                      <div class="sw-number_buttons">
                        <div
                          class="sw-number_up"
                          onclick="this.parentNode.parentNode.querySelector('input').stepUp()"
                        />
                        <div
                          class="sw-number_down"
                          onclick="this.parentNode.parentNode.querySelector('input').stepDown()"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="
                    col-12
                    d-flex
                    align-items-center
                    justify-content-between
                    mt-4
                  "
                >
                  <div class="col-6">
                    Inheritance/Gift
                  </div>
                  <div class="col-auto">
                    <div class="sw-number sw-number_right sw-number_percent">
                      <input
                        type="number"
                        placeholder="0"
                      >
                      <div class="swn-percent">
                        %
                      </div>
                      <div class="sw-number_buttons">
                        <div
                          class="sw-number_up"
                          onclick="this.parentNode.parentNode.querySelector('input').stepUp()"
                        />
                        <div
                          class="sw-number_down"
                          onclick="this.parentNode.parentNode.querySelector('input').stepDown()"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="
                    col-12
                    d-flex
                    align-items-center
                    justify-content-between
                    mt-4
                  "
                >
                  <div class="col-6">
                    Interest / Dividend Income
                  </div>
                  <div class="col-auto">
                    <div class="sw-number sw-number_right sw-number_percent">
                      <input
                        type="number"
                        placeholder="0"
                      >
                      <div class="swn-percent">
                        %
                      </div>
                      <div class="sw-number_buttons">
                        <div
                          class="sw-number_up"
                          onclick="this.parentNode.parentNode.querySelector('input').stepUp()"
                        />
                        <div
                          class="sw-number_down"
                          onclick="this.parentNode.parentNode.querySelector('input').stepDown()"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="
                    col-12
                    d-flex
                    align-items-center
                    justify-content-between
                    mt-4
                  "
                >
                  <div class="col-6">
                    Market Trading Profits
                  </div>
                  <div class="col-auto">
                    <div class="sw-number sw-number_right sw-number_percent">
                      <input
                        type="number"
                        placeholder="0"
                      >
                      <div class="swn-percent">
                        %
                      </div>
                      <div class="sw-number_buttons">
                        <div
                          class="sw-number_up"
                          onclick="this.parentNode.parentNode.querySelector('input').stepUp()"
                        />
                        <div
                          class="sw-number_down"
                          onclick="this.parentNode.parentNode.querySelector('input').stepDown()"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="
                    col-12
                    d-flex
                    align-items-center
                    justify-content-between
                    mt-4
                  "
                >
                  <div class="col-6">
                    Pension / Government Retirement benefit
                  </div>
                  <div class="col-auto">
                    <div class="sw-number sw-number_right sw-number_percent">
                      <input
                        type="number"
                        placeholder="0"
                      >
                      <div class="swn-percent">
                        %
                      </div>
                      <div class="sw-number_buttons">
                        <div
                          class="sw-number_up"
                          onclick="this.parentNode.parentNode.querySelector('input').stepUp()"
                        />
                        <div
                          class="sw-number_down"
                          onclick="this.parentNode.parentNode.querySelector('input').stepDown()"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="
                    col-12
                    d-flex
                    align-items-center
                    justify-content-between
                    mt-4
                  "
                >
                  <div class="col-6">
                    Property
                  </div>
                  <div class="col-auto">
                    <div class="sw-number sw-number_right sw-number_percent">
                      <input
                        type="number"
                        placeholder="0"
                      >
                      <div class="swn-percent">
                        %
                      </div>
                      <div class="sw-number_buttons">
                        <div
                          class="sw-number_up"
                          onclick="this.parentNode.parentNode.querySelector('input').stepUp()"
                        />
                        <div
                          class="sw-number_down"
                          onclick="this.parentNode.parentNode.querySelector('input').stepDown()"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="
                    col-12
                    d-flex
                    align-items-center
                    justify-content-between
                    mt-4
                  "
                >
                  <div class="col-6">
                    Other
                  </div>
                  <div class="col-auto">
                    <div class="sw-number sw-number_right sw-number_percent">
                      <input
                        type="number"
                        placeholder="0"
                      >
                      <div class="swn-percent">
                        %
                      </div>
                      <div class="sw-number_buttons">
                        <div
                          class="sw-number_up"
                          onclick="this.parentNode.parentNode.querySelector('input').stepUp()"
                        />
                        <div
                          class="sw-number_down"
                          onclick="this.parentNode.parentNode.querySelector('input').stepDown()"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12 mt-4">
                  <div class="sw-textfield w-100">
                    <input
                      type="text"
                      placeholder="Provide the reason here"
                    >
                  </div>

                  <div class="d-flex mt-45 flex-wrap bordered-wrapper pb-4">
                    <div class="col-12">
                      <div class="sw-form-progress">
                        <div class="swfp-bar" />
                        <div class="swfp-progress" />
                      </div>
                    </div>
                    <div
                      class="
                        col-12
                        d-flex
                        justify-content-between
                        mt-4
                        sw-form-total
                      "
                    >
                      <div>Total</div>
                      <div>53 <span>%</span></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 mt-5">
          <h3 class="ypd-typo-title">
            Regulatory information
          </h3>
          <div class="d-flex align-items-center bordered-wrapper flex-wrap">
            <h5 class="col-10 ypd-typo-text mt-5">
              Are you or anyone in your immediate family employed by or
              registered with a broker-dealer, investment advisor, futures
              commission merchant, hedge fund, exchange or other financial
              services firm (aka a "Financial Firm")?
            </h5>
            <div class="col-2 d-flex justify-content-end">
              <div class="sw-switch">
                <input
                  type="checkbox"
                  id="switch-2"
                  name="switch-2"
                  v-model="secondBlock"
                >
                <label for="switch-2" />
              </div>
            </div>

            <div
              class="col-12 d-flex flex-wrap mt-4"
              v-if="secondBlock"
            >
              <div class="col-md-6 col-12 pe-md-2">
                <div class="sw-label">
                  <div class="sw-label-text">
                    Relationts
                  </div>
                  <div class="d-flex swl-content">
                    <v-select
                      :options="options"
                      close-on-select
                      placeholder="Choose option"
                      class="sw-select"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-6 col-12 ps-md-2 mt-3 mt-md-auto">
                <div class="sw-label">
                  <div class="sw-label-text">
                    Holder full name
                  </div>
                  <div class="d-flex swl-content">
                    <v-select
                      :options="options"
                      close-on-select
                      placeholder="Choose option"
                      class="sw-select"
                    />
                  </div>
                </div>
              </div>

              <div class="col-12 mt-3">
                <div class="sw-label">
                  <div class="sw-label-text">
                    Company
                  </div>
                  <div class="d-flex swl-content">
                    <div class="sw-textfield w-100">
                      <input
                        type="text"
                        placeholder="Enter company name"
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6 col-12 pe-md-2 mt-3">
                <div class="sw-label">
                  <div class="sw-label-text">
                    Company country
                  </div>
                  <div class="d-flex swl-content">
                    <v-select
                      :options="options"
                      close-on-select
                      placeholder="Choose option"
                      class="sw-select"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-6 col-12 ps-md-2 mt-3">
                <div class="sw-label">
                  <div class="sw-label-text">
                    State/Province
                  </div>
                  <div class="d-flex swl-content">
                    <v-select
                      :options="options"
                      close-on-select
                      placeholder="Choose option"
                      class="sw-select"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-6 col-12 pe-md-2 mt-3">
                <div class="sw-label">
                  <div class="sw-label-text">
                    Company city
                  </div>
                  <div class="d-flex swl-content">
                    <div class="sw-textfield w-100">
                      <input
                        type="text"
                        placeholder="Enter company city"
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6 col-12 ps-md-2 mt-3">
                <div class="sw-label">
                  <div class="sw-label-text">
                    Postal code
                  </div>
                  <div class="d-flex swl-content">
                    <div class="sw-textfield w-100">
                      <input
                        type="text"
                        placeholder="Enter postal code"
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 mt-3">
                <div class="sw-label">
                  <div class="sw-label-text">
                    Company full address
                  </div>
                  <div class="d-flex swl-content">
                    <div class="sw-textfield w-100">
                      <input
                        type="text"
                        placeholder="Enter street address, building, office number, etc."
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6 col-12 pe-md-2 mt-3">
                <div class="sw-label">
                  <div class="sw-label-text">
                    Company phone
                  </div>
                  <div class="d-flex swl-content">
                    <div class="sw-textfield w-100">
                      <input
                        type="text"
                        placeholder="Enter company phone"
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6 col-12 ps-md-2 mt-3">
                <div class="sw-label">
                  <div class="sw-label-text">
                    Company e-mail
                  </div>
                  <div class="d-flex swl-content">
                    <div class="sw-textfield w-100">
                      <input
                        type="text"
                        placeholder="Enter company e-mail"
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 mt-3 mb-45">
                <div class="d-flex align-items-center">
                  <div
                    class="swi plus-square swi_mini d-inline-block me-2"
                  />
                  Add one more affiliated person
                </div>
              </div>
            </div>
          </div>

          <div
            class="d-flex align-items-center bordered-wrapper py-4 flex-wrap"
          >
            <h5 class="col-10 ypd-typo-text">
              Are you a director, a 10% shareholder or a policy-making officer
              of any publicly traded company?
            </h5>
            <div class="col-2 d-flex justify-content-end">
              <div class="sw-switch">
                <input
                  type="checkbox"
                  id="switch-3"
                  name="switch-3"
                  v-model="thirdBlock"
                >
                <label for="switch-3" />
              </div>
            </div>

            <div
              class="col-12 d-flex flex-wrap mt-4"
              v-if="thirdBlock"
            >
              <div class="col-12 mt-3">
                <div class="sw-label">
                  <div class="sw-label-text">
                    Stock symbols
                  </div>
                  <div class="d-flex swl-content">
                    <div class="sw-textfield w-100">
                      <input
                        type="text"
                        placeholder="Enter comma separated list"
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="d-flex align-items-center bordered-wrapper py-4 flex-wrap"
          >
            <h5 class="col-10 ypd-typo-text">
              I have never invest before
            </h5>
            <div class="col-2 d-flex justify-content-end">
              <div class="sw-switch">
                <input
                  type="checkbox"
                  id="switch-4"
                  name="switch-4"
                  v-model="foursBlock"
                >
                <label for="switch-4" />
              </div>
            </div>

            <div class="col-12 mt-4">
              <div class="ypd-typo-small-extra">
                So you know how this works. What did you invest in before?
              </div>
            </div>
            <div
              class="col-12 d-flex flex-wrap mt-4"
              v-if="foursBlock"
            >
              <h5 class="col-12 mb-2 ypd-typo-subtext">
                Stocks
              </h5>
              <div class="col-12 d-flex flex-wrap justify-content-between">
                <div class="col-md-4 col-12 pe-md-2">
                  <div class="sw-label">
                    <div class="sw-label-text">
                      Years of experience
                    </div>
                    <div class="d-flex swl-content">
                      <v-select
                        :options="options"
                        close-on-select
                        placeholder="0"
                        class="sw-select"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-12 px-md-1">
                  <div class="sw-label">
                    <div class="sw-label-text">
                      Trades per year
                    </div>
                    <div class="d-flex swl-content">
                      <v-select
                        :options="options"
                        close-on-select
                        placeholder="0"
                        class="sw-select"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-12 ps-md-2">
                  <div class="sw-label">
                    <div class="sw-label-text">
                      Knowledge level
                    </div>
                    <div class="d-flex swl-content">
                      <v-select
                        :options="options"
                        close-on-select
                        placeholder="No knowledge"
                        class="sw-select"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <h5 class="col-12 mt-4 mb-2 ypd-typo-subtext">
                Bonds
              </h5>
              <div class="col-12 d-flex flex-wrap justify-content-between">
                <div class="col-md-4 col-12 pe-md-2">
                  <div class="sw-label">
                    <div class="sw-label-text">
                      Years of experience
                    </div>
                    <div class="d-flex swl-content">
                      <v-select
                        :options="options"
                        close-on-select
                        placeholder="0"
                        class="sw-select"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-12 px-md-1">
                  <div class="sw-label">
                    <div class="sw-label-text">
                      Trades per year
                    </div>
                    <div class="d-flex swl-content">
                      <v-select
                        :options="options"
                        close-on-select
                        placeholder="0"
                        class="sw-select"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-12 ps-md-2">
                  <div class="sw-label">
                    <div class="sw-label-text">
                      Knowledge level
                    </div>
                    <div class="d-flex swl-content">
                      <v-select
                        :options="options"
                        close-on-select
                        placeholder="No knowledge"
                        class="sw-select"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <h5 class="col-12 mt-4 mb-2 ypd-typo-subtext">
                Options
              </h5>
              <div class="col-12 d-flex flex-wrap justify-content-between">
                <div class="col-md-4 col-12 pe-md-2">
                  <div class="sw-label">
                    <div class="sw-label-text">
                      Years of experience
                    </div>
                    <div class="d-flex swl-content">
                      <v-select
                        :options="options"
                        close-on-select
                        placeholder="0"
                        class="sw-select"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-12 px-md-1">
                  <div class="sw-label">
                    <div class="sw-label-text">
                      Trades per year
                    </div>
                    <div class="d-flex swl-content">
                      <v-select
                        :options="options"
                        close-on-select
                        placeholder="0"
                        class="sw-select"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-12 ps-md-2">
                  <div class="sw-label">
                    <div class="sw-label-text">
                      Knowledge level
                    </div>
                    <div class="d-flex swl-content">
                      <v-select
                        :options="options"
                        close-on-select
                        placeholder="No knowledge"
                        class="sw-select"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <h5 class="col-12 mt-4 mb-2 ypd-typo-subtext">
                Futures
              </h5>
              <div class="col-12 d-flex flex-wrap justify-content-between">
                <div class="col-md-4 col-12 pe-md-2">
                  <div class="sw-label">
                    <div class="sw-label-text">
                      Years of experience
                    </div>
                    <div class="d-flex swl-content">
                      <v-select
                        :options="options"
                        close-on-select
                        placeholder="0"
                        class="sw-select"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-12 px-md-1">
                  <div class="sw-label">
                    <div class="sw-label-text">
                      Trades per year
                    </div>
                    <div class="d-flex swl-content">
                      <v-select
                        :options="options"
                        close-on-select
                        placeholder="0"
                        class="sw-select"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-12 ps-md-2">
                  <div class="sw-label">
                    <div class="sw-label-text">
                      Knowledge level
                    </div>
                    <div class="d-flex swl-content">
                      <v-select
                        :options="options"
                        close-on-select
                        placeholder="No knowledge"
                        class="sw-select"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <h5 class="col-12 mt-4 mb-2 ypd-typo-subtext">
                Currency/Forex
              </h5>
              <div class="col-12 d-flex flex-wrap justify-content-between">
                <div class="col-md-4 col-12 pe-md-2">
                  <div class="sw-label">
                    <div class="sw-label-text">
                      Years of experience
                    </div>
                    <div class="d-flex swl-content">
                      <v-select
                        :options="options"
                        close-on-select
                        placeholder="0"
                        class="sw-select"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-12 px-md-1">
                  <div class="sw-label">
                    <div class="sw-label-text">
                      Trades per year
                    </div>
                    <div class="d-flex swl-content">
                      <v-select
                        :options="options"
                        close-on-select
                        placeholder="0"
                        class="sw-select"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-12 ps-md-2">
                  <div class="sw-label">
                    <div class="sw-label-text">
                      Knowledge level
                    </div>
                    <div class="d-flex swl-content">
                      <v-select
                        :options="options"
                        close-on-select
                        placeholder="No knowledge"
                        class="sw-select"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-auto mt-5">
        <div class="btn btn-primary col-12 mt-3 px-4 button-padding">
          Continue →
        </div>
      </div>
    </div>
  </MainLayout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout.vue";
import { mapActions, mapGetters } from "vuex";
import api from "@/services/api";

export default {
  components: {
    MainLayout,
  },
  name: "YourPersonalDetails",
  data: () => {
    return {
      firstBlock: false,
      secondBlock: false,
      thirdBlock: false,
      foursBlock: true,
      abFirstBlock: false,
      abSecondBlock: false,
      abThirdBlock: false,
      abFoursBlock: false,
      abFiveBlock: false,
      activeTab: "about",
      options: ["First option", "Second option"],
      form: null
    };
  },
    computed: {
    ...mapGetters(["onbForm"]),
    investmentObjectives() {
      let objectives = '';
      ['Growth', 'Hedging', 'Preservation/Income', 'Speculation'].forEach((value) => {
        // lifeSituation keys are in lowerkeys
        if (![null, undefined, "", false].includes(this.form.lifeSituation[value.toLowerCase()])) {
          objectives = objectives == '' ? value : `${objectives}, ${value}`;
        }
      })
      return objectives;
    },
    signValid() {
      return (
        this.signData.sign !== "" &&
        this.signData.sign ===
        this.form.aboutYou.first + " " + this.form.aboutYou.last
      );
    },
    genEmail() {
      if (!this.form) return '';
      return `${this.form.aboutYou.first}.${this.form.aboutYou.last}`.replace(/[\W_-]+/g,".")+`@clients.simplewealth.ch`
    },
    formListId() {
      if (!this.form) return [];
      if (
        [
          'Andorra',
          'Channel Islands and Jersey',
          'Gibraltar',
          'Guernsey',
          'Ireland',
          'Isle of Man',
          'Liechtenstein',
          'Monaco',
          'Norway',
          'San Marino',
          'Switzerland',
          'United Kingdom'
        ].includes(
          this.form.aboutYou.residenceAddr.country
        )
      ) {
        return [
          4041, 3230, 3044, 3085, 4070, 9490, 4154, 3077, 4022, 3043,
          6112, 3207, 4420, 5009, 4421, 3058, 4016, 6108, 3070, 3089, 3071,
          3024, 4024, 5013, 2109, 3094, 3081, 3074, 2192, 9130, 5001
        ];
      } else {
        return [
          4041, 3230, 3044, 3085, 4070, 9490, 4154, 3077, 4022, 3043,
          4772, 6108, 3089, 2109, 4538, 4762, 4757, 4758, 4756, 4753, 4759,
          4754, 4451, 4781, 4760, 4783, 4750, 4769, 4767, 4763, 5001
        ];
      }
    },
  },
  methods: {
    ...mapActions(["loginTsSend", "signTsSend"]),
    capitalize(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    getNow() {
      this.date = new Date().toGMTString();
    },
    sign() {
      let valid = true;

      if (!this.signData.check) {
        this.signDataValid.check = false;
        valid = false;
      }
      if (!this.signValid) {
        this.signDataValid.sign = false;
        valid = false;
      }
      if (!valid) {
        setTimeout(() => {
          document.getElementsByClassName("form-error")[0].scrollIntoView({
            behavior: "smooth",
          });
        }, 100);
      } else {
        this.signTsSend();

        this.$store.state.isDataCheck = true;
        this.$store.state.blur = true;

        this.startTimer();

        let vm = this;
        setTimeout(() => {
          vm.checkData();
        }, 5000)

      }
    },
    startTimer() {
      let timeMax = 39;
      let time = timeMax;
      let vm = this;
      this.$store.state.reviewTimer = setInterval(() => {
        time = time - 0.05;
        if (time <= 0) {
          clearInterval(vm.$store.state.reviewTimer);
          vm.$store.state.reviewTimer = null;
          vm.$store.state.isDataCheck = false;
          vm.$store.state.blur = false;
          vm.$store.state.timerPercent = 0;
          vm.$router.push({
            name: "ThankYou",
            params: {
              id: vm.$route.params.id,
            },
          });
        } else {
          vm.$store.state.timerPercent = 100 - (time * 100) / timeMax;
        }
      }, 50);
    },
    async checkData() {
      const res = await api.get("/onboarding/" + this.$route.params.id);

      if (res.data.data.ibxml && res.data.ibxml.success == true) {
        this.$store.state.isDataCheck = false;
        this.$store.state.blur = false;
        this.$router.push({
          name: "ThankYou",
          params: {
            id: this.$route.params.id,
          },
        });
      } else if (res.data.data.ibxml && res.data.data.ibxml.success == false) {
        this.$store.state.dataCheckErrors = res.data.data.ibxml.errors;
        clearInterval(this.$store.state.reviewTimer);
        this.$store.state.reviewTimer = null;
      } else {
        let vm = this;
        if (this.$store.state.isDataCheck) {
          setTimeout(() => {
            vm.checkData();
          }, 5000)
        }
      }
    },
    async fetchFormNames() {
      const res = await api.get("/forms.json");
      this.formNames = res.data;
    },
  },
  beforeUnmount() {
    clearInterval(this.timer);
    this.$store.state.isDataCheck = false;
    this.$store.state.blur = false;
    this.$store.state.dataCheckErrors = null
  },
  async mounted() {
    await this.fetchFormNames();
    this.form = this.onbForm;

    let vm = this;
    this.timer = setInterval(() => {
      vm.getNow();
    }, 1000);
    this.loginTsSend();
  },
};
</script>